import { theme } from '@chakra-ui/core'
import { css } from '@emotion/core'

// [container, breakpoint]
const sizes = {
    sm: ['520px', '576px'],
    md: ['720px', '768px'],
    lg: ['960px', '992px'],
    xl: ['1170px', '1200px'],
}

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1])
    return bp
}, [])

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            green: '#234B21',
            limeGreen: '#AAC44A',
            lightGrey: '#F2F2F2',
            red: '#DC5015',
            grey: '#f0f0f0',
            greyTranslucent: '#f0f0f080',
            darkGrey: '#505050',
            black: '#666666',
            white: '#FFFFFF',
        },
    },
    fonts: {
        ...theme.fonts,
        heading: 'Futura PT W01 Bold, sans-serif',
        body: 'Futura PT W01 Book, sans-serif',
    },
}

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
}

// Global styles designs: link to the client's style guide if there is one.
const fonts = css`
    @font-face {
        font-family: 'Futura PT W01 Book';
        src: url('/fonts/aafdd021-88d4-41d8-8e15-fe91f83e4b17.woff2') format('woff2'),
            url('/fonts/81d44d4b-ac07-4ad4-8cdd-4df771a3c1c3.woff') format('woff');
    }
    @font-face {
        font-family: 'Futura PT W01 Bold';
        src: url('/fonts/1a34942e-33ed-43bb-b229-7460d55b49f7.woff2') format('woff2'),
            url('/fonts/baecea54-cfd3-4578-8717-abf89eba62b6.woff') format('woff');
    }
`

const listStyles = css`
    ol:not([data-testid='breadcrumb'] > ol),
    ul:not([class^='DayPicker']) {
        font-size: 16px;
        line-height: 18px;
        margin: revert;
        padding: revert;
    }
    ul:not([class^='DayPicker']):not([class^='indicators']) {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        font-size: 16px;
        line-height: 18px;
        li {
            line-height: 30px;
            padding-left: 20px;
            margin-left: -20px;
            position: relative;
            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 10px;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: ${EditorTheme.colors.brand.limeGreen};
            }
        }
    }
`

const typography = css`
    h1 {
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;

        @media (max-width: 478px) {
            font-size: 24px;
            line-height: 30px;
        }
    }

    h2 {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;

        @media (max-width: 478px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    h4 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;
    }

    h5 {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;
    }

    h6 {
        font-size: 10px;
        line-height: 12px;
        margin-bottom: 15px;

        font-family: 'Futura PT W01 Bold', sans-serif;
    }

    p {
        font-size: 16px;
        line-height: 22px;

        font-family: 'Futura PT W01 Book', sans-serif;
    }

    a:not([type='button']) {
        text-decoration: underline;
        color: ${EditorTheme.colors.brand.darkGrey};
    }

    a:hover:not([type='button']) {
        color: ${EditorTheme.colors.brand.limeGreen};
    }

    blockquote {
        padding-left: 1.5rem;
        position: relative;
        border-left: 5px solid #ccc;

        p {
            font-style: italic;
        }
    }
`

export const GlobalStyles = css`
    ${fonts}
    ${listStyles}
    ${typography}
`
