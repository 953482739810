const { darken } = require('polished')

export const primary = {
    accentColor: 'rgba(220,80,21,1)',
    textColor: 'rgba(220,80,21,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'white',
        _active: {
            backgroundColor: darken(0.1, 'rgba(220,80,21,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(220,80,21,1)'),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const secondary = {
    accentColor: 'rgba(35, 75, 33, 1)',
    textColor: 'rgba(170,196,74,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
   solidProps: {
        color: 'white',
        _active: {
            backgroundColor: darken(0.1, 'rgba(35, 75, 33, 1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(35, 75, 33, 1)'),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const success = {
    accentColor: 'rgba(170,196,67,1)',
    textColor: 'rgba(170,196,67,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.white',
        _active: {
            backgroundColor: darken(0.1, 'rgba(170,196,67,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(170,196,67,1)'),
            color: 'rgba(255,255,255,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const white = {
    accentColor: 'rgba(255,255,255,1)',
    textColor: 'rgba(255,255,255,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'rgba(0,0,0,1)',
        _active: {
            backgroundColor: darken(0.1, 'rgba(255,255,255,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(255,255,255,1)'),
            color: 'rgba(0,0,0,1)',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const black = {
    accentColor: '#000000',
    textColor: '#000000',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'rgba(255,255,255,1)',
        _active: {
            backgroundColor: darken(0.1, 'rgba(0,0,0,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(0,0,0,1)'),
            color: 'brand.white',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const lightGrey = {
    accentColor: 'rgba(242,242,242,1)',
    textColor: 'rgba(242,242,242,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: 'brand.white',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.black',
       _active: {
            backgroundColor: darken(0.1, 'rgba(242,242,242,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(242,242,242,1)'),
            color: 'brand.black',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const darkGrey = {
    accentColor: 'rgba(80,80,80,1)',
    textColor: 'rgba(80,80,80,1)',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: 'brand.white',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.white',
        _active: {
            backgroundColor: darken(0.1, 'rgba(80,80,80,1)'),
        },
        _hover: {
            backgroundColor: darken(0.05, 'rgba(80,80,80,1)'),
            color: 'brand.white',
            textDecoration: 'none'
        }
    },
    outlineProps: {
        backgroundColor: 'brand.white',
    },
}

export const transparentWhite = {
    accentColor: 'transparent',
    textColor: 'brand.white',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: 'brand.white',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.white',
        _hover: {
            color: 'brand.white',
            textDecoration: 'none'
        }
    },
}

export const transparentLightGrey = {
    accentColor: 'transparent',
    textColor: 'brand.lightGrey',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: 'brand.lightGrey',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.lightGrey',
        _hover: {
            color: 'brand.lightGrey',
            textDecoration: 'none'
        }
    },
}

export const transparentDarkGrey = {
    accentColor: 'transparent',
    textColor: 'brand.darkGrey',
    sharedProps: {
        px: '1em',
        lineHeight: '45px',
        height: '50px',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: 'brand.darkGrey',
        minWidth: '2.5rem',
        paddingX: '80px',
    },
    solidProps: {
        color: 'brand.darkGrey',
        _hover: {
            color: 'brand.darkGrey',
            textDecoration: 'none'
        }
    },
}