import React, { useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode'
import IFrameHtml from '@peracto/peracto-editor-ui/dist/IFrameHtml'

const IFrameContent = styled.div`
    position: relative;

    p {
        text-align: center;
        padding: 1rem 0;
    }
`

const IFrameOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
`

let Icon = () => (
    <div className="flex items-center justify-center w-full py-10">
        <FontAwesomeIcon icon={faCode} size="6x" />
    </div>
)

const EmbedComponent = ({ state: { url }, isEditMode }) => {
    const inStorefront = !window?.location?.pathname.includes('/content/edit/')

    useEffect(() => {
        const siteControlScript = document.createElement('script')

        siteControlScript.setAttribute('src', '//l.getsitecontrol.com/e4z3n1xw.js')

        document.body.appendChild(siteControlScript)
    }, [])

    return (
        <>
            <IFrameContent className="content__embed">
                {url && url.length > 0 ? (
                    <>
                        <Content url={url} />
                        {isEditMode && <IFrameOverlay />}
                    </>
                ) : inStorefront ? (
                    <></>
                ) : (
                    <Icon />
                )}
            </IFrameContent>
        </>
    )
}

const Content = ({ url }) => {
    return useMemo(() => <IFrameHtml className="embed-iframe" content={url} />, [url])
}

export default EmbedComponent
