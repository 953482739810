import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode'

export const defaultTranslations = {
    pluginName: 'Embed',
    pluginDescription: 'Insert an iframe.',
}

export const defaultSettings = {
    defaultText: '<p>Enter text here.</p>',

    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faCode} size="3x" />,
}
