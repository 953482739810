import React from 'react'
import { ThemeProvider } from 'styled-components'

import Peracto from '@peracto/peracto'
import Config from '@peracto/peracto-config'
import PeractoUser from '@peracto/peracto-user'
import PeractoCatalogue from '@peracto/peracto-catalogue'
import PeractoCommon from '@peracto/peracto-common'
import PeractoContent from '@peracto/peracto-content'
import PeractoLocations from '@peracto/peracto-locations'
import PeractoPromotions from '@peracto/peracto-promotions'

import { EditorTheme, AdminTheme, GlobalStyles } from './theme'
import * as buttonVariants from './components/Button/buttonVariants'

import blocks from './blocks'
import { menu } from './menu'

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser(),
        PeractoCommon(),
        PeractoCatalogue(),
        PeractoLocations(),
        PeractoPromotions(),
        PeractoContent(),
    ],
    editorConfig: {
        theme: EditorTheme,
        colorOptions: [
            { label: 'Brand Green', value: EditorTheme.colors.brand.green },
            {
                label: 'Brand Lime Green',
                value: EditorTheme.colors.brand.limeGreen,
            },
            { label: 'Brand Red', value: EditorTheme.colors.brand.red },
            { label: 'Brand Black', value: EditorTheme.colors.brand.black },
            { label: 'Brand White', value: EditorTheme.colors.brand.white },
        ],
        locales: ['en_GB', 'es_ES', 'fr_FR'],
        blocks,
        buttonVariants,
        inputTypes: {},
        stylesheets: [],
        globalStyles: GlobalStyles,
    },
    features: {
        article: {
            blogToggle: true,
        },
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            exportProductData: true,
            productVariants: true,
            hideClearancePrice: true,
            availabilitySelectFilter: true,
        },
        content: {
            indexContent: true,
            productRelationships: {
                article: true,
                blog: true,
            },
        },
        user: {
            companySettings: true,
            exportUserData: true,
        },
        uploads: {
            CKEditorBypass: true,
        },
    },
    menu,
})

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
)

export default App
