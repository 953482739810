import React from 'react'

const EmbedForm = ({ onChange, state: { url = '' } }) => {
    return (
        <>
            <div className="form-group">
                <label className="mb-1">Iframe Embed Code</label>
                <textarea
                    className="form-control"
                    rows={6}
                    value={url}
                    onChange={e => onChange({ url: e.target.value })}
                />
            </div>
        </>
    )
}

export default EmbedForm
